$(document).ready(() => {
    const checkScrollPosition = (e) => {
        let currentPosition = e
            ? $(e.target).scrollTop()
            : $(window).scrollTop();
        if (currentPosition > 50) {
            $("#back-to-top").fadeIn();
        } else {
            $("#back-to-top").fadeOut();
        }
        if (currentPosition > 1) {
            $(".main-header").addClass("bg-scroll", 3000, "ease-in");
        } else {
            $(".main-header").removeClass("bg-scroll", 3000, "ease-in");
        }
    };
    //show the back to top button
    $(window).scroll((e) => {
        checkScrollPosition(e);
    });

    // scroll body to 0px on click
    $("#back-to-top").click(() => {
        $("body,html").animate(
            {
                scrollTop: 0,
            },
            400
        );
        return false;
    });

    // Add smooth scrolling to all links
    $("a").on("click", (e) => {
        // Make sure this.hash has a value before overriding default behavior
        if (e.target.hash !== "") {
            // Prevent default anchor click behavior
            e.preventDefault();

            // Store hash
            let hash = e.target.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $("html, body").animate(
                {
                    scrollTop: $(hash).offset().top,
                },
                1500,
                () => {
                    // Add hash (#) to URL when done scrolling (default click behavior)
                    window.location.hash = hash;
                }
            );
        }
    });

    const removeActiveClassFromContactCards = () => {
        let contactCards = $(".contact-card").toArray();
        contactCards.forEach((element) => {
            if ($(element).hasClass("active")) {
                $(element).removeClass("active");
            }
        });
    };

    $(".contact-card").on("click", (e) => {
        removeActiveClassFromContactCards();
        $(e.currentTarget).addClass("active");
    });

    $('[href="#contact-us-email"]').trigger("click");

    checkScrollPosition();
});
